.theme-townsquare {
  --color-primary-100: hsl(338, 100%, 92%);
  --color-primary-200: hsl(338, 100%, 82%);
  --color-primary-300: hsl(338, 100%, 72%);
  --color-primary-400: hsl(338, 100%, 62%);
  --color-primary-500: hsl(338, 100%, 52%);
  --color-primary-600: hsl(338, 100%, 42%);
  --color-primary-700: hsl(338, 100%, 32%);
  --color-primary-800: hsl(338, 100%, 22%);
  --color-primary-900: hsl(338, 100%, 12%);
}

.theme-coworklocal {
  --color-primary-100: hsl(196, 65%, 96%);
  --color-primary-200: hsl(196, 65%, 86%);
  --color-primary-300: hsl(196, 65%, 76%);
  --color-primary-400: hsl(196, 65%, 66%);
  --color-primary-500: hsl(196, 65%, 56%);
  --color-primary-600: hsl(196, 65%, 46%);
  --color-primary-700: hsl(196, 65%, 36%);
  --color-primary-800: hsl(196, 65%, 26%);
  --color-primary-900: hsl(196, 65%, 16%);
}

body {
  outline: none;
}

*:focus {
  outline: none;
}
button:focus {
  outline: none;
}

#root {
  overflow: hidden;
}
